import { CountUp } from 'countup.js';

function createBlocks(dataValCommas, counterWrapper) {
    const valuesArray = dataValCommas.split('');

    valuesArray.map((value) => {
        const newNumber = document.createElement('span');
        const newContent = document.createTextNode(value);
        newNumber.appendChild(newContent);
        counterWrapper.appendChild(newNumber);

        return true;
    });
}

function counterAnimation(startVal, endVal) {
    const numAnim = new CountUp('counter__overlay__num', endVal);
    if (!numAnim.error) {
        numAnim.start();
    } else {
        console.error(numAnim.error);
    }
}

function activateAnimation(counterWrapper, startVal, endVal) {
    if (!counterWrapper.classList.contains('counter--active')) {
        counterWrapper.classList.add('counter--active');
        counterAnimation(startVal, endVal);
    }
}

window.addEventListener('load', () => {
    const counter = document.querySelector('.counter');

    if (counter != null) {
        const counterWrapper = counter.querySelector('.counter-wrap');
        const dataVal = counter.getAttribute('data-value');
        const counterOverlay = document.getElementById('counter__overlay');
        let dataValCommas = '';
        const startVal = 0;
        const endVal = dataVal;
        const numberWithCommas = (x) => {
            dataValCommas = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        };
        numberWithCommas(dataVal);

        createBlocks(dataValCommas, counterWrapper, counterOverlay);

        const observer = new IntersectionObserver((entries) => {
            // isIntersecting is true when element and viewport are overlapping, isIntersecting is false when element and viewport don't overlap
            if (entries[0].isIntersecting === true) {
                activateAnimation(counterWrapper, startVal, endVal);
            }
        }, { threshold: [1] });
        observer.observe(counterWrapper);
    }
});
